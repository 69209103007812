import { Box, Paper, Typography } from "@mui/material";
import { openDB } from "idb";
import React, { useEffect, useState } from "react";
import { Speechmatics } from "speechmatics";

interface TranscriptionProps {
	audioDataId: Number;
	setTranscription: Function;
	setLanguage: Function;
}

export const Transcription: React.FC<TranscriptionProps> = (props) => {
	const [transcription, setTranscription] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const token = "nHFKkuFYv4f9295qtRIjGrBttbYTRZMD";
	const [audioData, setAudioData] = useState<Blob | null>(null);
	const sm = new Speechmatics(token);
	const [language, setLanguage] = useState("en");
	const [transcriptions, setTranscriptions] = useState<{ [key: number]: string }>(
		JSON.parse(localStorage.getItem("voice-transcriptions") || "{}")
	);

	useEffect(() => {
		if (props.audioDataId && transcriptions[props.audioDataId as number]) {
			setIsLoading(false);
			setTranscription(transcriptions[props.audioDataId as number]);
			props.setTranscription(transcriptions[props.audioDataId as number]);
			return;
		}
		openDB("AudioDatabase", 1).then((db) => {
			if (props.audioDataId === null || props.audioDataId === undefined) {
				console.error("No audio data ID provided");
				return;
			}
			const tx = db.transaction("audioFiles", "readonly");
			const store = tx.objectStore("audioFiles");
			try {
				store.get(props.audioDataId as IDBValidKey).then((data) => {
					if (!data) {
						console.error("No data found for ID:", props.audioDataId);
						return;
					}
					tx.done.then(() => {
						db.close();
						setLanguage(data.language);
						props.setLanguage(data.language);
						console.log("Audio data:", data.data);
						setAudioData(data.data as Blob);
					});
				});
			} catch (error) {
				console.error("Error getting data for ID:", props.audioDataId);
			}
		});
	}, [props.audioDataId]);

	useEffect(() => {
		if (props.audioDataId && transcriptions[props.audioDataId as number]) {
			setTranscription(transcriptions[props.audioDataId as number]);
			props.setTranscription(transcriptions[props.audioDataId as number]);
		} else if (audioData) {
			setIsLoading(true);
			callApiWithAudio();
		}
	}, [audioData]);

	const callApiWithAudio = async () => {
		if (!audioData) {
			setIsLoading(false);
			console.error("No audio data to transcribe");
			return;
		}
		console.log("Calling API with audio data:", audioData);
		sm.batch
			.transcribe(
				{
					data: audioData,
					fileName: "audio.wav",
				},
				{ transcription_config: { language: language } },
				"text"
			)
			.then((response) => {
				console.log("Transcription response:", response);
				setTranscription(response as string);
				props.setTranscription(response as string);
				console.log("Transcription set:", response);
				setIsLoading(false);
				setTranscriptions({
					...transcriptions,
					[props.audioDataId as number]: response,
				} as { [key: number]: string });
			})
			.catch((error) => {
				console.error("Error transcribing audio:", error);
				setIsLoading(false);
			});
	};

	useEffect(() => {
		localStorage.setItem("voice-transcriptions", JSON.stringify(transcriptions));
	}, [transcriptions]);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				width: "100%",
				height: "calc(100vh - 110px)",
			}}
		>
			<Paper
				sx={{
					display: "flex",
					flexDirection: "column",
					width: "100%",
					height: "100%",
					p: "10px",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						width: "100%",
						height: "20px",
					}}
				>
					<Typography variant="h6">Raw transcription</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						marginTop: "10px",
						width: "100%",
						height: "calc(100% - 20px)",
						overflowX: "auto",
						minHeight: "100px",
						scrollbarColor: "background: rgb(76, 116, 166)",
						scrollbarGutter: "scrollbar",
						"&::-webkit-scrollbar": {
							width: "7px",
							borderRadius: "10px",
						},
						"&::-webkit-scrollbar-thumb": {
							background: "rgb(76, 116, 166)",
							borderRadius: "10px",
						},
						"&::-webkit-scrollbar-thumb:hover": {
							backgroundColor: "rgba(0, 0, 0, 0.3)",
						},
					}}
				>
					{isLoading && <p>Loading...</p>}
					{!isLoading && (
						<Typography
							sx={{
								whiteSpace: "pre-wrap",
								wordWrap: "break-word",
								overflowWrap: "break-word",
								width: "100%",
								textAlign: "justify",
							}}
						>
							{transcription}
						</Typography>
					)}
				</Box>
			</Paper>
		</Box>
	);
};

export default Transcription;
