import {
	CalendarMonth,
	Checklist,
	Mail,
	MedicationLiquid,
	People,
	Phone,
	Rule,
} from "@mui/icons-material";
import {
	Box,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Tooltip,
	Typography,
} from "@mui/material";

interface DataSummaryRfpProps {
	language: string;
	data: any;
}

export const DataSummaryRfp: React.FC<DataSummaryRfpProps> = (props) => {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				height: "100%",
				width: "100%",
				p: "20px",
				mb: "40px",
			}}
		>
			<Typography variant="h4" sx={{ mb: "20px", mx: "auto" }}>
				Data Summary
			</Typography>
			<CustomerReference data={props.data} />
			<ProductReference data={props.data} />
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					height: "100%",
					width: "100%",
				}}
			>
				<Typography variant="h5" sx={{ mb: "20px" }}>
					Deadline
				</Typography>
				<Typography variant="body1" sx={{ ml: "25px", mb: "6px" }}>
					<b>Date:</b> {props.data?.deadline}
				</Typography>
				{ManageReferences(props.data?.deadline_reference)}
				<OtherDates data={props.data} />
			</Box>
			<ContactsReference data={props.data} />
			{props.data?.evaluation_criteria && (
				<>
					<Typography variant="h5" sx={{ mb: "20px" }}>
						Evaluation Criteria
					</Typography>
					<List>
						{props.data?.evaluation_criteria?.map((el: any, idx: number) => {
							return (
								<ListItem>
									<ListItemIcon>
										<Rule />
									</ListItemIcon>
									<ListItemText
										primary={`${el.criterion} (${el.percentage})`}
										secondary={el.description}
									/>
								</ListItem>
							);
						})}
					</List>
					{ManageReferences(props.data?.evaluation_criteria_reference)}
				</>
			)}
			{props.data?.requirements && (
				<>
					<Typography variant="h5" sx={{ mb: "6px" }}>
						Requirements
					</Typography>
					<List>
						{props.data?.requirements?.map((el: any, idx: number) => {
							return (
								<ListItem>
									<ListItemIcon>
										<Checklist />
									</ListItemIcon>
									<ListItemText
										primary={el}
										secondary={ManageReferences(
											props.data?.requirements_reference
										)}
									/>
								</ListItem>
							);
						})}
					</List>
				</>
			)}
		</Box>
	);
};

function Capitalize(str: string | null) {
	if (str === null || str === undefined) return "";
	return str.charAt(0).toUpperCase() + str.slice(1);
}

interface DataReferenceProps {
	data: any;
}

const ManageReferences = (data: any) => {
	if (data === null || data === undefined || data.length === 0) return null;
	return (
		<Typography variant="body1" sx={{ ml: "25px", mb: "20px", fontSize: "12px" }}>
			References:{" "}
			{data?.map((el: any, idx: number) => {
				return (
					<Box key={`${idx}-${el.filename}`}>
						<i>{el.filename}</i>, page {el.page}
						{idx + 1 !== data.length ? " - " : ""}
					</Box>
				);
			})}
		</Typography>
	);
};

const CustomerReference: React.FC<DataReferenceProps> = (props) => {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				height: "100%",
				width: "100%",
			}}
		>
			<Typography variant="h5" sx={{ mb: "20px" }}>
				Customer References
			</Typography>
			<Typography variant="body1" sx={{ ml: "25px", mb: "6px" }}>
				<b>Name:</b> {props.data?.client}
			</Typography>
			{ManageReferences(props.data?.client_references)}
		</Box>
	);
};

const ProductReference: React.FC<DataReferenceProps> = (props) => {
	if (!props.data?.product?.length) return null;
	if (
		props.data?.product &&
		Array.isArray(props.data.product) &&
		props.data?.product.length > 0
	) {
		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					mt: "20px",
					mb: "20px",
					height: "100%",
					width: "100%",
				}}
			>
				<Typography variant="h5" sx={{}}>
					Product
				</Typography>
				<List>
					{props.data?.product?.map((el: any, idx: number) => {
						var references = [];
						for (let i = 0; i < props.data?.product_reference.length; i++) {
							if (props.data?.product_reference[i].filename === el) {
								references.push(props.data?.product_reference[i]);
							}
						}
						return (
							<ListItem key={idx}>
								<ListItemIcon>
									<MedicationLiquid />
								</ListItemIcon>
								<ListItemText
									primary={Capitalize(el)}
									secondary={ManageReferences(references)}
								/>
							</ListItem>
						);
					})}
				</List>
			</Box>
		);
	} else {
		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					height: "100%",
					width: "100%",
				}}
			>
				<Typography variant="body1" sx={{ ml: "25px", mb: "6px" }}>
					<b>Product:</b> {Capitalize(props.data?.product)}
				</Typography>
				{ManageReferences(props.data?.product_reference)}
			</Box>
		);
	}
};

const is_element_filled = (el: string) => {
	return (
		el !== "" &&
		el !== null &&
		el !== undefined &&
		el.toLocaleLowerCase() !== "null" &&
		el.toLocaleLowerCase() !== "undefined" &&
		el !== " " &&
		el.toLocaleLowerCase() !== "not found" &&
		el.toLocaleLowerCase() !== "n/a"
	);
};

const ContactsReference: React.FC<DataReferenceProps> = (props) => {
	if (!props.data?.contacts?.length) return null;
	if (props.data.contacts.length && props.data.contacts[0].constructor === Object) {
		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					height: "100%",
					width: "500px",
				}}
			>
				<Typography variant="h5" sx={{ mb: "20px" }}>
					Contacts
				</Typography>
				<List>
					{props.data?.contacts?.map((el: any, idx: number) => {
						var secondary = "";
						if (el.role && is_element_filled(el.role)) {
							secondary += ` ${el.role}`;
						}
						if (el.entity && is_element_filled(el.entity)) {
							secondary += (secondary ? ", " : "") + `${el.entity}`;
						}
						var secondaryAction = null;
						if (el.email && is_element_filled(el.email)) {
							secondaryAction = (
								<Tooltip title={el.email}>
									<IconButton
										onClick={() =>
											(window.location.href = `mailto:${el.email}`)
										}
									>
										<Mail />
									</IconButton>
								</Tooltip>
							);
						} else if (el.phone && is_element_filled(el.phone)) {
							secondaryAction = (
								<Tooltip title={el.phone}>
									<IconButton
										onClick={() => (window.location.href = `tel:${el.phone}`)}
									>
										<Phone />
									</IconButton>
								</Tooltip>
							);
						}

						return (
							<ListItem key={idx} secondaryAction={secondaryAction}>
								<ListItemIcon
									onClick={() => {
										if (el.email && is_element_filled(el.email)) {
											window.location.href = `mailto:${el.email}`;
										}
									}}
								>
									<Tooltip title={el.email}>
										<People />
									</Tooltip>
								</ListItemIcon>
								<ListItemText primary={el.name} secondary={secondary} />
							</ListItem>
						);
					})}
				</List>
			</Box>
		);
	} else {
		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					height: "100%",
					width: "100%",
				}}
			>
				<Typography variant="h5" sx={{ mb: "20px" }}>
					Contacts
				</Typography>
				<List>
					{props.data?.contacts?.map((el: any, idx: number) => {
						return (
							<ListItem>
								<ListItemIcon>
									<People />
								</ListItemIcon>
								<ListItemText primary={el} />
							</ListItem>
						);
					})}
				</List>
			</Box>
		);
	}
};

const OtherDates: React.FC<DataReferenceProps> = (props) => {
	if (props.data?.other_dates === null || props.data?.other_dates === undefined) {
		return null;
	}
	if (!Array.isArray(props.data?.other_dates) && !(props.data?.other_dates instanceof Object)) {
		return <Typography>props.data.other_dates</Typography>;
	}
	if (!props.data?.other_dates?.length) {
		return null;
	}
	if (props.data.other_dates.length && props.data.other_dates[0].constructor === Object) {
		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					height: "100%",
					width: "100%",
				}}
			>
				<Typography variant="h5" sx={{ mb: "20px" }}>
					Other Dates
				</Typography>
				<List>
					{props.data?.other_dates?.map((el: any, idx: number) => {
						return (
							<ListItem>
								<ListItemIcon>
									<CalendarMonth />
								</ListItemIcon>
								<ListItemText primary={el.date} secondary={el.description} />
							</ListItem>
						);
					})}
				</List>
			</Box>
		);
	} else {
		return (
			<>
				{props.data?.other_dates && (
					<>
						<Typography
							variant="body1"
							sx={{ mb: "20px", ml: "25px", fontStyle: "italic" }}
						>
							Other Dates
						</Typography>
						<List
							dense={true}
							disablePadding={true}
							sx={{
								ml: "50px",
								mb: "20px",
							}}
						>
							{props.data?.other_dates?.map((el: any, idx: number) => {
								return (
									<ListItem key={idx} dense={true} disablePadding={true}>
										<ListItemIcon>
											<CalendarMonth />
										</ListItemIcon>
										<ListItemText primary={el} />
									</ListItem>
								);
							})}
						</List>
					</>
				)}
			</>
		);
	}
};
