import axios from "axios";
import { config } from "../config/configurations";

const instance = axios.create({
	withCredentials: true,
	baseURL: config.REACT_APP_URL_BACKEND_API,
	headers: {
		appId: "poc-sbrain-web",
		version: "1.0.1",
		client: "sbrain-web",
	},
});

instance.interceptors.response.use(
	(request) => {
		return request;
	},
	async (error) => {
		return Promise.reject(error);
	}
);

export { instance };
