import {
	CloudUpload as CloudUploadIcon,
	HighlightOff as HighlightOffIcon,
	PanTool as PanToolIcon,
	Send,
} from "@mui/icons-material";
import {
	Box,
	Button,
	Container,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	SelectChangeEvent,
	Typography,
	styled,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";

import { instance } from "../../apis/baseApi";
import "./file_picker.css";

interface FileUploadProps {
	apiCall: string;
	onFileUpload: (files: any, data: any, language: string) => void;
	selectLanguage: boolean;
	extensionFiles?: string;
}

const VisuallyHiddenInput = styled("input")({
	clip: "rect(0 0 0 0)",
	clipPath: "inset(50%)",
	height: 1,
	overflow: "hidden",
	position: "absolute",
	bottom: 0,
	left: 0,
	whiteSpace: "nowrap",
	width: 1,
});

const FileUpload: React.FC<FileUploadProps> = ({
	apiCall,
	onFileUpload,
	selectLanguage,
	extensionFiles,
}) => {
	const [selectedFiles, setSelectedFiles] = useState<File[] | []>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [language, setLanguage] = useState<string>("english");

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event.target.files) {
			return;
		} else {
			if (selectedFiles) {
				setSelectedFiles([...Array.from(selectedFiles), ...Array.from(event.target.files)]);
			} else {
				setSelectedFiles(Array.from(event.target.files) || []);
			}
		}
	};

	useEffect(() => {
		if (!selectedFiles) {
			setSelectedFiles([]);
		}
	}, [selectedFiles]);

	const handleUpload = () => {
		if (!selectedFiles) {
			return;
		}
		setIsLoading(true);
		const formData = new FormData();
		selectedFiles.forEach((file) => {
			formData.append("rfps", file, file.name);
		});
		if (!language) {
			setLanguage("english");
		}
		instance
			.post(apiCall, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then((response: any) => {
				setIsLoading(false);
				onFileUpload(selectedFiles, response.data, language);
				setSelectedFiles([]);
			})
			.catch((error: any) => {
				setIsLoading(false);
				console.log(error);
			});
	};

	const handleLanguageChange = (event: SelectChangeEvent) => {
		setLanguage(event.target.value as string);
	};

	const handleDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		document.getElementById("dropzone")?.classList.remove("dragging");

		if (event.dataTransfer.items) {
			const files = event.dataTransfer.files;
			if (files) {
				if (selectedFiles) {
					setSelectedFiles([...Array.from(selectedFiles), ...Array.from(files)]);
				} else {
					setSelectedFiles(Array.from(files) || null);
				}
			}
		}
	}, []);

	return (
		<Container
			sx={{
				display: "flex",
				flexDirection: "column",
				minHeight: selectedFiles.length > 0 ? "280px" : "190px",
				maxHeight: "30%",
				my: "10px",
				width: "100%",
			}}
		>
			<Paper
				elevation={3}
				sx={{
					padding: "20px",
					".dragging": {
						backgroundColor: "#eee",
						cursor: "copy",
						border: "2px dashed #ccc",
						borderRadius: "4px",
						padding: "20px",
						textAlign: "center",
						height: "150px",
					},
				}}
			>
				<Typography variant="h5">File Upload</Typography>
				{!selectedFiles?.length && !isLoading && (
					<Box
						id="dropzone"
						onDrop={handleDrop}
						onDragOver={(e: any) => {
							e.preventDefault();
							e.dataTransfer.dropEffect = "copy";
							document.getElementById("dropzone")?.classList.add("dragging");
						}}
						onDragEnter={(e: any) => {
							e.preventDefault();
							document.getElementById("dropzone")?.classList.add("dragging");
						}}
						onDragLeave={(e: any) => {
							e.preventDefault();
							document.getElementById("dropzone")?.classList.remove("dragging");
						}}
						sx={{
							display: "flex",
							flexDirection: "column",
							margin: "auto",
							border: "2px dashed #ccc",
							borderRadius: "4px",
							padding: "20px",
							textAlign: "center",
							cursor: "pointer",
						}}
					>
						<Box component="span" sx={{ fontSize: "1.5rem", margin: "auto" }}>
							<Typography>
								Drag and drop <PanToolIcon /> a file here or{" "}
							</Typography>
							<Button
								component="label"
								variant="contained"
								startIcon={<CloudUploadIcon />}
								sx={{ marginTop: "15px" }}
							>
								Upload file
								<VisuallyHiddenInput
									type="file"
									onChange={handleFileChange}
									accept={extensionFiles ? extensionFiles : ".docx,.xlsx,.pdf"}
									multiple
								/>
							</Button>
						</Box>
					</Box>
				)}
				{selectedFiles?.length > 0 && !isLoading && (
					<>
						<Box
							sx={{
								display: "flex",
								marginTop: "20px",
								flexDirection: "column",
								justifyContent: "center",
								overflowY: "auto",
								maxHeight: "75px",
							}}
						>
							{selectedFiles?.map((file) => {
								return (
									<Box
										key={file.name}
										sx={{
											margin: "auto",
											marginLeft: "10px",
											display: "flex",
											flexDirection: "row",
										}}
									>
										<IconButton
											onClick={() =>
												setSelectedFiles(
													selectedFiles.filter(
														(f) => f.name !== file.name
													)
												)
											}
											sx={{ marginLeft: "10px" }}
										>
											<HighlightOffIcon fontSize="small" />
										</IconButton>
										<Box
											sx={{
												margin: "auto",
												marginLeft: "10px",
												display: "flex",
												flexDirection: "row",
											}}
										>
											{file.name} ({file.size} bytes)
										</Box>
									</Box>
								);
							})}
						</Box>
						{selectLanguage && (
							<>
								<Box
									sx={{
										display: "flex",
										marginTop: "20px",
										flexDirection: "row",
										justifyContent: "end",
									}}
								>
									<FormControl fullWidth>
										<InputLabel id="change-language">Language</InputLabel>
										<Select
											labelId="change-language"
											id="change-language-select"
											label="Select Language"
											value={language}
											defaultValue={language}
											onChange={handleLanguageChange}
										>
											<MenuItem value="english">English</MenuItem>
											<MenuItem value="french">French</MenuItem>
											<MenuItem value="spanish">Spanish</MenuItem>
										</Select>
									</FormControl>
								</Box>
							</>
						)}
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "end",
							}}
						>
							<Button
								sx={{ marginTop: "20px" }}
								variant="contained"
								color="primary"
								onClick={(e) => handleUpload()}
								endIcon={<Send />}
							>
								Upload
							</Button>
						</Box>
					</>
				)}
				{isLoading && (
					<Box sx={{ display: "flex", flexDirection: "column" }}>
						<Typography
							sx={{
								marginTop: "20px",
								marginBottom: "20px",
								textAlign: "center",
								verticalAlign: "middle",
								my: "auto",
							}}
						>
							Uploading and processing file, please wait...
						</Typography>
						<Box sx={{ marginTop: "5px", marginBottom: "5px", marginLeft: "10px" }}>
							<div className="pacman-environment">
								<div className="border"></div>
								<div className="path">
									<div className="dots">
										<svg className="pacman pacman-final" viewBox="0 0 100 100">
											<circle
												className="pac-v1 pac-v6 pac-v7"
												cx="50%"
												cy="50%"
												r="25%"
											></circle>
										</svg>
									</div>
								</div>
								<div className="border"></div>
							</div>
						</Box>
					</Box>
				)}
			</Paper>
		</Container>
	);
};

export default FileUpload;
