import React, { createContext, useContext, useState } from "react";

// Create the context
export const LoginContext = createContext<any>(null);

export const useAuth = () => useContext(LoginContext);

// Create the provider component
export const LoginProvider: React.FC<any> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem("isLoggedIn") === "true");

  // Function to handle login
  const login = () => {
    // Perform your login logic here
    setIsLoggedIn(true);
    localStorage.setItem("isLoggedIn", "true");
  };

  // Function to handle logout
  const logout = () => {
    // Perform your logout logic here
    setIsLoggedIn(false);
    localStorage.removeItem("isLoggedIn");
  };

  // Value object to be provided by the context
  const value = {
    isLoggedIn,
    login,
    logout,
  };

  return <LoginContext.Provider value={value}>{children}</LoginContext.Provider>;
};
