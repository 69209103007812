import { CopyAll, QuestionAnswer, QuestionAnswerOutlined } from "@mui/icons-material";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	IconButton,
	ListItem,
	ListItemText,
	Tooltip,
	Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Prediction } from "../assistant-layout/widgets/prediction";

interface RFPViewerReqProps {
	v: any;
	expandReq: boolean;
	forceAnswer: boolean;
}

export const RFPViewerReqMac: React.FC<RFPViewerReqProps> = ({
	v,
	expandReq,
	forceAnswer,
}: RFPViewerReqProps) => {
	const [expand, setExpand] = useState<boolean>(expandReq);
	const [answer, setAnswer] = useState<boolean>(forceAnswer);

	useEffect(() => {
		setAnswer(forceAnswer);
	}, [forceAnswer]);

	useEffect(() => {
		setExpand(expandReq);
	}, [expandReq]);

	return (
		<ListItem
			key={v.subtheme.title}
			secondaryAction={
				<IconButton
					edge="end"
					aria-label="delete"
					onClick={() => {
						navigator.clipboard.writeText(
							v.subtheme.title + "\n" + v.questions.join("\n")
						);
					}}
				>
					<CopyAll />
				</IconButton>
			}
		>
			<Accordion
				slotProps={{
					transition: { unmountOnExit: true },
				}}
				sx={{
					width: "100%",
				}}
				expanded={expand}
				onClick={() => {
					setExpand(!expand);
				}}
			>
				<AccordionSummary id="panel-header" aria-controls="panel-content">
					<ListItemText primary={v.subtheme.criteria} secondary={v.subtheme.title} />
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<Tooltip title="Answer to this requirement">
							<IconButton
								edge="end"
								aria-label="delete"
								onClick={() => {
									setAnswer(!answer);
								}}
							>
								{!answer && <QuestionAnswer />}
								{answer && <QuestionAnswerOutlined />}
							</IconButton>
						</Tooltip>
					</Box>
				</AccordionSummary>
				<AccordionDetails>
					<ul>
						{v?.questions?.map((q: any) => {
							return <li key={q}>{q}</li>;
						})}
					</ul>
					<>
						{answer && (
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									justifyContent: "center",
									width: "100%",
								}}
							>
								<Typography
									variant="h6"
									sx={{
										mb: 2,
									}}
								>
									Answer
								</Typography>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										justifyContent: "center",
										width: "100%",
										border: "1px solid #a5a5a5",
										borderRadius: "5px",
									}}
								>
									<Prediction
										storageKey={v.subtheme.title}
										sentence={v.subtheme.title + "\n" + v.questions.join("\n")}
									/>
								</Box>
							</Box>
						)}
					</>
				</AccordionDetails>
			</Accordion>
		</ListItem>
	);
};
