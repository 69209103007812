import { Box, List, Typography } from "@mui/material";
import { RFPViewerReqMac } from "./viewerReqMac";

interface RFPViewerReqProps {
	key: string;
	title: string;
	value: any;
	expandReqs: any;
	forceAnswers: any;
}

export const RFPViewerReq: React.FC<RFPViewerReqProps> = ({
	title,
	value,
	expandReqs,
	forceAnswers,
}: RFPViewerReqProps) => {
	return (
		<Box
			sx={{
				width: "100%",
				border: "none",
			}}
		>
			<Typography variant="h6" sx={{ mt: 2 }}>
				{title}
			</Typography>
			<List>
				{(value as any).map((v: any, idx: number) => {
					if (!v?.subtheme?.title || !expandReqs?.hasOwnProperty(v?.subtheme?.title)) {
						return null;
					}
					return (
						<RFPViewerReqMac
							key={v?.subtheme?.title}
							v={v}
							expandReq={expandReqs[v?.subtheme?.title]}
							forceAnswer={forceAnswers[v?.subtheme?.title]}
						/>
					);
				})}
			</List>
		</Box>
	);
};
