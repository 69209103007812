import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { Box, Drawer, IconButton, Paper } from "@mui/material";
import { useState } from "react";
import { Assistant } from "../components/assistant-layout";
import { RFPViewer } from "../components/rfps/viewer";
import { FileUploadHistory } from "../components/uploadWithHistory";

export const RFPExtract: React.FC = () => {
	const usageCaseStorageKey = "last_rfp_use_case_3";
	const usageHistoryStorageKey = "rfp_use_case_3_history";
	const extensionFiles = ".pdf";
	const [openAssistant, setOpenAssistant] = useState<boolean>(false);
	const [selectedFile, setSelectedFile] = useState<{}>(
		localStorage.getItem(usageCaseStorageKey)
			? JSON.parse(localStorage.getItem(usageCaseStorageKey) || "{}")
			: {}
	);
	const [apiCall] = useState<string>("/v1/rfps/document");

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				position: "relative",
				overflow: "hidden",
				height: "calc(100vh - 112px)",
				maxWidth: "100vw",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					contain: "content",
					alignItems: "center",
					justifyContent: "center",
					width: "100%",
					height: "100%",
					border: "none",
				}}
			>
				<FileUploadHistory
					apiCall={apiCall}
					setSelectedFile={setSelectedFile}
					setSelectedLanguage={null}
					usageCaseStorageKey={usageCaseStorageKey}
					usageHistoryStorageKey={usageHistoryStorageKey}
					extensionFiles={extensionFiles}
				/>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
						height: "calc(100% - 25px)",
						my: "10px",
						width: "70%",
						mr: "25px",
					}}
				>
					{selectedFile && (
						<Box
							sx={{
								display: "flex",
								flexDirection: "rows",
								alignItems: "center",
								justifyContent: "center",
								height: "100%",
								width: "100%",
							}}
						>
							<Paper
								sx={{
									alignItems: "center",
									justifyContent: "center",
									height: "100%",
									width: "98%",
									p: "10px",
								}}
							>
								<RFPViewer
									setSelectedFile={setSelectedFile}
									file={selectedFile}
									usageCaseStorageKey={usageCaseStorageKey}
									usageHistoryStorageKey={usageHistoryStorageKey}
								/>
							</Paper>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									position: "absolute",
									justifyContent: "center",
									height: "20%",
									right: "-1px",
									border: "1px solid #a5a5a5",
									borderRadius: "5px 0px 0px 5px",
									width: "25px",
									p: "10px",
									backgroundColor: "#f0f0f0",
									"&:hover": {
										backgroundColor: "#ebebeb",
									},
									cursor: "grab",
								}}
								onClick={() => {
									setOpenAssistant(true);
								}}
							>
								<IconButton>
									<ArrowLeft />
								</IconButton>
							</Box>

							<Drawer
								open={openAssistant}
								anchor="right"
								PaperProps={{
									sx: {
										height: "calc(100% - 64px)",
										top: 64,
									},
								}}
								sx={{
									width: "50%",
									p: "10px",
									mr: "10px",
									display: "flex",
								}}
							>
								<Box
									sx={{
										display: "flex",
										flexDirection: "row",
										height: "100%",
										p: "10px",
									}}
								>
									<Box
										sx={{
											display: "flex",
											position: "absolute",
											top: "50%",
											bottom: "50%",
											left: "-5px",
											zIndex: 999999,
											flexDirection: "column",
											alignItems: "center",
											justifyContent: "center",
											border: "1px solid #a5a5a5",
											borderRadius: "0px 5px 5px 0px",
											width: "25px",
											height: "20%",
											margin: "auto",
											p: "10px",
											backgroundColor: "#f0f0f0",
											"&:hover": {
												backgroundColor: "#ebebeb",
											},
											cursor: "grab",
										}}
										onClick={() => {
											setOpenAssistant(false);
										}}
									>
										<IconButton>
											<ArrowRight />
										</IconButton>
									</Box>
									<Box
										sx={{
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
											justifyContent: "center",
											height: "100%",
											maxWidth: "500px",
										}}
									>
										<Assistant mode={"web"} />
									</Box>
								</Box>
							</Drawer>
						</Box>
					)}
				</Box>
			</Box>
		</Box>
	);
};
