import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AudioRecorder } from "../components/voice/audioRecorder";
import { Summary } from "../components/voice/summary";
import Transcription from "../components/voice/transcription";

export const VoiceMatch: React.FC = () => {
	const [file, setFile] = useState<any>();
	const [transcription, setTranscription] = useState("");
	const [language, setLanguage] = useState("en");

	useEffect(() => {
		console.log("VoiceMatch props:", file);
	}, [file]);

	useEffect(() => {
		console.log("VoiceMatch transcription:", transcription);
	}, [transcription]);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				height: "calc(100% - 55px)",
				width: "100%",
			}}
		>
			<AudioRecorder setFile={setFile} setLanguage={setLanguage} language={language} />
			<Box
				sx={{
					display: "flex",
					height: "100%",
					width: "calc(100% - 300px)",
				}}
			>
				<Box
					sx={{
						width: "48%",
						height: "100%",
					}}
				>
					<Transcription
						audioDataId={file}
						setTranscription={setTranscription}
						setLanguage={setLanguage}
					/>
				</Box>
				<Box
					sx={{
						width: "48%",
						height: "100%",
					}}
				>
					<Summary dataId={file} transcription={transcription} language={language} />
				</Box>
			</Box>
		</Box>
	);
};
