import { QuestionAnswerOutlined } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { RFPViewerReq } from "./viewerReq";

interface RFPViewerProps {
	file: any;
	setSelectedFile: (file: any) => void;
	usageCaseStorageKey: {};
	usageHistoryStorageKey: {};
}

export const RFPViewer: React.FC<RFPViewerProps> = (props: RFPViewerProps) => {
	const [expandReqs, setExpandReqs] = useState<{}>();
	const [forceAnswers, setForceAnswers] = useState<{}>();

	useEffect(() => {
		if (!props.file || !props.file.length) {
			return;
		}
		Object.entries(props.file[0]).map(([key, value]) => {
			(value as [])?.map((v: any) => {
				setExpandReqs({
					...expandReqs,
					[v.subtheme.title]: false,
				});
				setForceAnswers({
					...forceAnswers,
					[v.subtheme.title]: false,
				});
			});
		});
	}, []);

	const [activate, setActivate] = useState<boolean>(false);

	useEffect(() => {
		if (!props.file || !props.file.length) {
			return;
		}
		var tmp: { [key: string]: boolean } = Object.entries(props.file[0]).reduce(
			(acc, [key, value]) => {
				return {
					...acc,
					...(value as [])?.reduce((subAcc: { [key: string]: boolean }, v: any) => {
						return {
							...subAcc,
							[v.subtheme.title]: activate,
						};
					}, {}),
				};
			},
			{}
		);
		setExpandReqs(tmp);
		if (activate) {
			setForceAnswers(tmp);
		}
	}, [activate]);

	const handleAnswerAll = () => {
		setActivate(!activate);
	};

	return (
		<Box
			sx={{
				overflowY: "auto",
				height: "100%",
				"&::-webkit-scrollbar": {
					width: "7px",
					borderRadius: "10px",
				},
				"&::-webkit-scrollbar-thumb": {
					background: "rgb(76, 116, 166)",
					borderRadius: "10px",
				},
				"&::-webkit-scrollbar-thumb:hover": {
					backgroundColor: "rgba(0, 0, 0, 0.3)",
				},
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "right",
					justifyContent: "right",
					width: "100%",
					border: "none",
					p: 2,
				}}
			>
				<Button
					variant="contained"
					onClick={() => {
						handleAnswerAll();
					}}
					endIcon={<QuestionAnswerOutlined />}
				>
					Answer to all
				</Button>
			</Box>

			{props.file &&
				props.file.length &&
				Object.entries(props.file[0]).map(([key, value]) => {
					return (
						<RFPViewerReq
							key={key}
							title={key}
							value={value}
							forceAnswers={forceAnswers}
							expandReqs={expandReqs}
						/>
					);
				})}
		</Box>
	);
};
