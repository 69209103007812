import { AxiosResponse } from "axios";
import { instance } from "./baseApi";

export function answer(message: string): Promise<AxiosResponse> {
	return instance.post(`/v1/rfps/question`, {
		question: message,
	});
}

export function rfp(language: string): Promise<AxiosResponse> {
	return instance.get(`/v1/rfps/${language}`);
}

export function searchUsers(query: string): Promise<AxiosResponse> {
	return instance.get(`/v1/users/search?query=${query}`);
}

export function initFlow(text: string): Promise<AxiosResponse> {
	return instance.post(`/v1/rfps/init`, {
		text,
	});
}

export function getGraphRag(): Promise<AxiosResponse> {
	return instance.get(`/v1/aitour/graph`);
}

export function chat(prompt: string, conversationHistory?: []): Promise<AxiosResponse> {
	if (window.location.pathname === "/graphrag") {
		return instance.post(`/v1/aitour/local/search`, {
			text: prompt,
			conversation_history: conversationHistory,
		});
	}

	return instance.post(`/v1/rfps/question`, {
		prompt: prompt,
	});
}

export function chatSummary(prompt: any[]): Promise<AxiosResponse> {
	return instance.post(`/v1/rfps/questions/summary`, {
		prompt: prompt,
	});
}
