import { Box, Typography, Paper, Button } from '@mui/material';
import React from 'react';
import { RootStyle } from "../../style/rootStyle";
import AnimatedLogo from "../../assets/animatedLogo";
import HomeIcon from '@mui/icons-material/Home';

export const Error404: React.FC = () => {

    return (
        <RootStyle className="root-container">

            <Paper
                sx={{p: "2em",}}
            >
                <AnimatedLogo />
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        borderRadius: 5,
                        width: "fit-content",
                        mt: "2em"
                    }}
                >

                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        sx={{
                            color: "#1F3A5F"
                        }}
                    >
                        <Typography variant="h4">Oopss, we can afford it! So, so, sorry...<br/></Typography>
                        <Typography variant="h6">We can't find the page you're looking for.</Typography>
                        
                        <Typography variant="body2">404 - Not Found</Typography>
                    </Box>
                </Box>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        width: "100%",
                        borderRadius: 5,
                        mt: "2em"
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                            mt: "2em",
                            verticalAlign:"middle",
                        }}
                        onClick={() => {
                            window.location.href = "/";
                        
                        }}
                    >
                        <HomeIcon sx={{
                            mr: "0.5em"
                        
                        }}/>
                        Go Home
                    </Button>
                </Box>

            </Paper>
        </RootStyle >
    )
}