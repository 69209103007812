import DeleteIcon from "@mui/icons-material/Delete";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import {
	Box,
	IconButton,
	List,
	ListItem,
	MenuItem,
	Paper,
	Select,
	Typography,
} from "@mui/material";
import { openDB } from "idb";
import React, { useEffect, useRef, useState } from "react";
import { convertWebmBlobToWavBlob } from "./helper";

interface AudioRecorderProps {
	setFile: Function;
	setLanguage: Function;
	language: string;
}

const languages = [
	{ name: "English", code: "en" },
	{ name: "Spanish", code: "es" },
	{ name: "French", code: "fr" },
	{ name: "German", code: "de" },
];

export const AudioRecorder: React.FC<AudioRecorderProps> = (props) => {
	const [isRecording, setIsRecording] = useState(false);
	const [recordings, setRecordings] = useState<any[]>([]);
	const mediaRecorderRef = useRef<MediaRecorder | undefined>();
	const audioChunksRef = useRef<Blob[]>([]);
	const [rowSelected, setRowSelected] = useState<number | null>(null);

	useEffect(() => {
		// Initialize IndexedDB when the component mounts
		const initDB = async () => {
			console.log("initDB");
			const db = await openDB("AudioDatabase", 1, {
				upgrade(db) {
					if (!db.objectStoreNames.contains("audioFiles")) {
						db.createObjectStore("audioFiles", { keyPath: "id", autoIncrement: true });
					}
				},
			});
			await loadRecordings(db);
			console.log("loadRecordings");
		};

		initDB();
		props.setLanguage("en");
	}, []);

	const loadRecordings = async (db: any) => {
		const tx = db.transaction("audioFiles", "readonly");
		const store = tx.objectStore("audioFiles");
		const allRecordings = await store.getAll();
		setRecordings(allRecordings);
	};

	const startRecording = async () => {
		try {
			const stream = await navigator.mediaDevices.getUserMedia({
				audio: {
					echoCancellation: true,
					noiseSuppression: true,
					autoGainControl: true,
				},
			});
			mediaRecorderRef.current = new MediaRecorder(stream, { mimeType: "audio/webm" });

			mediaRecorderRef.current.ondataavailable = (event: BlobEvent) => {
				if (event.data.size > 0) {
					console.log("Data available:", event.data);
					console.log("Data available:", event.currentTarget);
					audioChunksRef.current.push(event.data);
				}
			};

			mediaRecorderRef.current.onstop = async () => {
				const audioBlob = new Blob(audioChunksRef.current, { type: "audio/webm" });
				await saveRecording(audioBlob);
				audioChunksRef.current = [];
			};

			mediaRecorderRef.current.start();
			setIsRecording(true);
		} catch (error) {
			console.error("Error accessing microphone:", error);
		}
	};

	const stopRecording = () => {
		if (mediaRecorderRef.current) {
			mediaRecorderRef.current.stop();
			setIsRecording(false);
		}
	};

	const saveRecording = async (audioBlob: Blob) => {
		const wavblob = await convertWebmBlobToWavBlob(audioBlob);
		const db = await openDB("AudioDatabase", 1);
		const tx = db.transaction("audioFiles", "readwrite");
		const store = tx.objectStore("audioFiles");
		const id = await store.add({
			data: wavblob,
			timestamp: Date.now(),
			language: props.language,
		});
		const allRecordings = await store.getAll();
		setRecordings(allRecordings);
		setRowSelected(Number(id));

		return id;
	};

	const deleteRecording = async (id: number) => {
		const db = await openDB("AudioDatabase", 1);
		const tx = db.transaction("audioFiles", "readwrite");
		const store = tx.objectStore("audioFiles");
		await store.delete(id);
		const allRecordings = await store.getAll();
		setRecordings(allRecordings);
		var transcriptions = JSON.parse(localStorage.getItem("transcriptions") || "{}");
		transcriptions[id] = undefined;
		localStorage.setItem("transcriptions", JSON.stringify(transcriptions));
	};

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				height: "calc(100vh - 120px)",
				width: "380px",
				mr: "25px",
			}}
		>
			<Paper
				sx={{
					display: "flex",
					width: "100%",
					height: "110px",
					scrollbarColor: "background: rgb(76, 116, 166)",
					scrollbarGutter: "scrollbar",
					"&::-webkit-scrollbar": {
						width: "7px",
						borderRadius: "10px",
					},
					"&::-webkit-scrollbar-thumb": {
						background: "rgb(76, 116, 166)",
						borderRadius: "10px",
					},
					"&::-webkit-scrollbar-thumb:hover": {
						backgroundColor: "rgba(0, 0, 0, 0.3)",
					},
				}}
			>
				<Box
					sx={{
						height: "100%",
						width: "100%",
					}}
				>
					<Typography
						variant="body1"
						sx={{
							fontSize: "24px",
							lineHeight: "1.5",
							fontFamily: "",
							fontWeight: "bold",
							mt: "10px",
							ml: "10px",
						}}
					>
						Voice recorder
					</Typography>

					<Box sx={{}}>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "center",
								width: "100%",
								mb: "10px",
							}}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "center",
									alignItems: "center",
									backgroundColor: "#f0f0f0",
									borderRadius: "15px",
								}}
							>
								<IconButton onClick={startRecording} disabled={isRecording}>
									<PlayCircleIcon
										sx={{ fontSize: 40 }}
										color={isRecording ? "disabled" : "primary"}
									/>
								</IconButton>
								<IconButton onClick={stopRecording} disabled={!isRecording}>
									<StopCircleIcon
										sx={{ fontSize: 40 }}
										color={!isRecording ? "disabled" : "primary"}
									/>
								</IconButton>
							</Box>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									backgroundColor: "white",
									ml: "10px",
								}}
							>
								<Select
									labelId="language-select-label"
									id="language-select"
									value={props.language}
									onChange={(event) => props.setLanguage(event.target.value)}
								>
									{languages.map((language) => (
										<MenuItem key={language.code} value={language.code}>
											{language.name}
										</MenuItem>
									))}
								</Select>
							</Box>
						</Box>
					</Box>
				</Box>
			</Paper>
			<Paper
				sx={{
					display: "flex",
					height: "100%",
					width: "100%",
					minHeight: "110px",
					mt: "10px",
					mb: "-10px",
					overflowX: "auto",
					scrollbarColor: "background: rgb(76, 116, 166)",
					scrollbarGutter: "scrollbar",
					"&::-webkit-scrollbar": {
						width: "7px",
						borderRadius: "10px",
					},
					"&::-webkit-scrollbar-thumb": {
						background: "rgb(76, 116, 166)",
						borderRadius: "10px",
					},
					"&::-webkit-scrollbar-thumb:hover": {
						backgroundColor: "rgba(0, 0, 0, 0.3)",
					},
				}}
			>
				<Box>
					<Typography
						variant="body1"
						sx={{
							fontSize: "24px",
							lineHeight: "1.5",
							fontFamily: "",
							fontWeight: "bold",
							mt: "10px",
							ml: "10px",
						}}
					>
						Recorded
					</Typography>
					<List>
						{recordings.map((recording) => (
							<ListItem
								key={recording.id}
								onClick={(event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
									console.log("Recording ID:", recording.id);
									setRowSelected(recording.id);
									props.setFile(recording.id);
								}}
								sx={{
									display: "flex",
									flexDirection: "column",
									cursor: "pointer",
									"&:hover": {
										backgroundColor: "rgba(0, 0, 0, 0.1)",
									},
									backgroundColor:
										rowSelected === recording.id ? "rgba(0, 0, 0, 0.1)" : "",
								}}
							>
								<Box sx={{ display: "flex", flexDirection: "column" }}>
									<Box sx={{ display: "flex", flexDirection: "column" }}>
										<Typography variant="body1">
											{new Date(recording.timestamp).toLocaleString()}
										</Typography>
									</Box>
									<Box sx={{ display: "flex", flexDirection: "row" }}>
										<audio
											controls
											preload="metadata"
											src={URL.createObjectURL(recording.data)}
										></audio>
										<IconButton onClick={() => deleteRecording(recording.id)}>
											<DeleteIcon />
										</IconButton>
									</Box>
								</Box>
							</ListItem>
						))}
					</List>
				</Box>
			</Paper>
		</Box>
	);
};
