import { Box, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { getGraphRag } from "../../../apis/assistant";

interface GraphDisplayProps {
	setMessages: Function;
	messages: any;
	setBotMessageCallback: Function;
	sentence: string;
}

export const GraphDisplay: React.FC<GraphDisplayProps> = (props) => {
	const [svgImage, setSvgImage] = useState<string>("");
	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		getGraphRag()
			.then((res: any) => {
				setSvgImage(res.data);
				setIsLoading(false);
			})
			.catch((err: any) => {
				console.error(err);
				setIsLoading(false);
			});
	}, []);

	const openInNewTab = () => {
		const newWindow = window.open();
		if (!newWindow) {
			return;
		}
		newWindow.document.write(svgImage);
		newWindow.document.close();
	};

	return (
		<Box
			sx={{
				width: "100%",
				border: "none",
			}}
		>
			{isLoading && (
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "40px",
						width: "40px",
					}}
				>
					<CircularProgress />
				</Box>
			)}
			{!isLoading && (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "170px",
					}}
				>
					<div
						onClick={openInNewTab}
						style={{
							justifyContent: "center",
							alignItems: "center",
							cursor: "pointer",
							width: "150px", // Restrict width for thumbnail
							height: "150px", // Restrict height for thumbnail
							overflow: "hidden",
							border: "1px solid #ccc",
						}}
					>
						<img
							src={"/assets/icons/svg_icon.jpg"}
							alt="RAG Graph"
							style={{ width: "100%", height: "100%" }}
						/>
					</div>
				</div>
			)}
		</Box>
	);
};
