// @ts-nocheck

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Link,
	Paper,
	styled,
	Typography,
} from "@mui/material";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import { useEffect, useState } from "react";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { chat } from "../../../apis/assistant";
import waiterLogo from "./waitIcon.svg";

import remarkBreaks from "remark-breaks";

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))({
	[`& .${tooltipClasses.tooltip}`]: {
		maxWidth: 500,
	},
});

const TooltipRenderer = ({ node, children }) => {
	/*
	const tooltipText = children[0].props.children;
	var hoverText = children[1].props.children;
	*/
	const tooltipText = node.children.find((child) => child.tagName === "title").children[0].value;
	var hoverText = children[1].props.children;

	if (tooltipText === "Plan Local d'Urbanisme de Paris et ses Implications") {
		console.log("tooltipText ", tooltipText);
	}
	if (Array.isArray(hoverText)) {
		hoverText = hoverText
			.map((item) => {
				if (typeof item === "string") {
					return item;
				}
				return "";
			})
			.join("")
			.replaceAll(/\xa0/g, "");
	}
	return (
		<Accordion
			sx={{
				border: "1px solid #a5a5a5",
				borderRadius: "5px",
				margin: "5px",
				width: "100%",
				minHeight: "32px",
			}}
		>
			<AccordionSummary
				expandIcon={<ArrowDownwardIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header"
				sx={{
					minHeight: "30px",
					height: "30px",
					borderRadius: "5px",
					backgroundColor: "#f0f0f0",
				}}
			>
				<Typography
					variant="body2"
					color="textSecondary"
					component="p"
					sx={{
						fontSize: "13.3333px",
						fontStyle: "italic",
						fontWeight: "bold",
						margin: "auto",
					}}
				>
					{tooltipText}
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Typography
					sx={{
						fontSize: "13.3333px",
						textAlign: "justify",
					}}
				>
					<Markdown remarkPlugins={[remarkBreaks]} rehypePlugins={[rehypeRaw]}>
						{hoverText.replaceAll(/\xa0/g, "")}
					</Markdown>
				</Typography>
			</AccordionDetails>
		</Accordion>
	);
};

export const Prediction = (props: any) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [prediction, setPrediction] = useState<any>(null);

	useEffect(() => {
		setIsLoading(true);
		if (props?.storageKey) {
			const prediction = localStorage.getItem(`prediction-${props.storageKey}`);
			if (prediction) {
				setPrediction(JSON.parse(prediction));
				setIsLoading(false);
			}
		}
	}, []);

	useEffect(() => {
		if (!isLoading) {
			scrollToBottom();
			return;
		}
		if (prediction) {
			setIsLoading(false);
			return;
		}
		chat(props.sentence, props.conversationHistory)
			.then((res: any) => {
				if (props.setBotMessageCallback) {
					props.setBotMessageCallback(res.data);
				}
				setPrediction(res.data);
				setIsLoading(false);
				if (props?.storageKey) {
					res.data["title"] = props.storageKey;
					localStorage.setItem(
						`prediction-${props.storageKey}`,
						JSON.stringify(res.data)
					);
				}
			})
			.catch((err: any) => {
				console.log(err);
				setIsLoading(false);
			});
	}, [isLoading]);

	const scrollToBottom = () => {
		if (!props?.messagesEndRef || !props?.messagesEndRef?.current || props?.messagesEndRef?.current === null) {
			return;
		}
		props.messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
	};

	return (
		<>
			<Box
				sx={{
					backgroundColor: "transparent",
					fontSize: "13.3333px",
					fontWeight: "400",
					px: "14px",
					py: "10px",
					borderRadius: "5px",
				}}
			>
				{isLoading && (
					<Box sx={{ display: "flex" }}>
						<img
							src={waiterLogo}
							alt="waiter"
							style={{
								width: "30px",
								height: "30px",
								margin: "auto",
							}}
						/>
					</Box>
				)}
				{prediction && (
					<>
						<Typography
							sx={{
								fontSize: "13.3333px",
								textAlign: "justify",
							}}
						>
							<Markdown
								rehypePlugins={[rehypeRaw]}
								components={{
									sup: ({ node, ...props }) => <sup {...props} />,
									p: ({ node, children }) => <p>{children}</p>,
									tooltip: TooltipRenderer,
									paragraph: ({ node, children }) => {
										return (
											<p
												style={{
													textAlign: "center",
													fontStyle: "italic",
													fontWeight: "bold",
													fontSize: "13.3333px",
													margin: "auto",
													color: "#8e8e8e",
												}}
											>
												{children}
											</p>
										);
									},
								}}
							>
								{prediction.answer}
							</Markdown>
						</Typography>
						{prediction?.sources?.length > 0 && (
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									marginTop: "10px",
									width: "100%",
									color: "black !important",
								}}
							>
								<Typography
									variant="body2"
									color="textSecondary"
									component="p"
									sx={{
										fontSize: "13.3333px",
										fontStyle: "italic",
										fontWeight: "bold",
										margin: "auto",
									}}
								>
									Sources ({prediction.sources.length})
								</Typography>
								{prediction.sources.map((source: any, index: number) => {
									return (
										<Paper
											key={index}
											sx={{
												maxWidth: "100%",
												display: "flex",
												flexDirection: "row",
												p: "5px",
												m: "2px",
												alignContent: "center",
												justifyContent: "center",
												"&:hover": {
													backgroundColor: "#f0f0f0",
												},
												border: "1px solid #a5a5a5",
												borderRadius: "5px",
											}}
										>
											<Box
												sx={{
													display: "flex",
													flexDirection: "column",
													alignItems: "center",
													justifyContent: "center",
													width: "20px",
													height: "20px",
													borderRadius: "5px",
													backgroundColor: "#f0f0f0",
													fontSize: "10px",
													color: "black",
													fontWeight: "bold",
													border: "1px solid #a5a5a5",
													margin: "auto",
												}}
											>
												<Typography
													variant="body2"
													component="p"
													sx={{
														fontSize: "10px",
														margin: "auto",
													}}
												>
													{index + 1}
												</Typography>
											</Box>
											<Link
												href={source.url}
												target="_blank"
												variant="body2"
												sx={{
													fontSize: "13.3333px",
													marginLeft: "5px",
													wordBreak: "break-all",
													"@media (max-width: 600px)": {
														fontSize: "12px",
													},
													width: "100%",
												}}
											>
												{source.title}
											</Link>
										</Paper>
									);
								})}
							</Box>
						)}
					</>
				)}
			</Box>
		</>
	);
};
