import { DeleteOutline, FilePresent } from "@mui/icons-material";
import {
	Box,
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Paper,
	Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import FileUpload from "../file_picker";

interface FileHistoryProps {
	setSelectedFile: Function;
	setSelectedLanguage: Function | null;
	usageCaseStorageKey: string;
	usageHistoryStorageKey: string;
	apiCall: string;
	extensionFiles?: string;
}

interface HistoryProps {
	files: any;
	language: string;
	data: any;
	datetime: Date;
}

export const FileUploadHistory: React.FC<FileHistoryProps> = ({
	apiCall,
	setSelectedFile,
	setSelectedLanguage,
	usageCaseStorageKey,
	usageHistoryStorageKey,
	extensionFiles,
}) => {
	const [history, setHistory] = useState<HistoryProps[]>(
		localStorage.getItem(usageHistoryStorageKey)
			? JSON.parse(localStorage.getItem(usageHistoryStorageKey) || "[]")
			: []
	);

	const handleFileUpload = (files: [], data: any, language: string) => {
		if (history) {
			setHistory([
				...Array.from(history),
				{
					files: files.map((f: any) => {
						return f.name;
					}),
					language: language,
					data: data,
					datetime: new Date(),
				},
			]);
		} else {
			setHistory([
				{
					files: files.map((f: any) => {
						return f.name;
					}),
					language: language,
					data: data,
					datetime: new Date(),
				},
			]);
		}
		localStorage.setItem(usageCaseStorageKey, JSON.stringify(data));
		setSelectedFile(data);
		if (setSelectedLanguage) {
			setSelectedLanguage(language);
		}
	};

	useEffect(() => {
		if (history) {
			localStorage.setItem(usageHistoryStorageKey, JSON.stringify(history));
		}
	}, [history, usageHistoryStorageKey]);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				flexShrink: 0,
				flexGrow: 1,
				flexBasis: 0,
				height: "100%",
				width: "30%",
			}}
		>
			<FileUpload
				apiCall={apiCall}
				onFileUpload={handleFileUpload}
				selectLanguage={setSelectedLanguage !== null}
				extensionFiles={extensionFiles}
			/>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					minHeight: "120px",
					mt: "25px",
					mb: "10px",
					mr: "25px",
					ml: "25px",
				}}
			>
				<Paper
					sx={{
						display: "flex",
						flexDirection: "column",
						height: "100%",
						width: "100%",
						minHeight: "20%",
						padding: "20px",
						overflowX: "hidden",
						scrollbarColor: "background: rgb(76, 116, 166)",
						scrollbarGutter: "scrollbar",
						"&::-webkit-scrollbar": {
							width: "7px",
							borderRadius: "10px",
						},
						"&::-webkit-scrollbar-thumb": {
							background: "rgb(76, 116, 166)",
							borderRadius: "10px",
						},
						"&::-webkit-scrollbar-thumb:hover": {
							backgroundColor: "rgba(0, 0, 0, 0.3)",
						},
					}}
				>
					<Typography variant="h5" sx={{ display: "flex", height: "20px", mb: "20px" }}>
						Upload history
					</Typography>
					{history.length > 0 ? (
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "space-between",
								border: "2px dashed #CCC",
							}}
						>
							{history?.map((item: any, index: number) => {
								return (
									<Box key={index}>
										<Box
											onClick={() => {
												setSelectedFile(item.data);
												if (setSelectedLanguage)
													setSelectedLanguage(item.language);
											}}
											key={index}
											sx={{
												display: "flex",
												flexDirection: "column",
												minHeight: "120px",
												my: "10px",
												cursor: "pointer",
												"&:hover": {
													backgroundColor: "rgba(0, 0, 0, 0.1)",
												},
												padding: "20px",
												mx: "10px",
											}}
										>
											<Box
												sx={{
													display: "flex",
													flexDirection: "row",
													justifyContent: "space-between",
												}}
											>
												<Typography variant="body1" sx={{}}>
													{setSelectedLanguage
														? `Language: ${item?.language}`
														: ""}
												</Typography>

												<IconButton
													onClick={() => {
														setHistory(
															history.filter((_, i) => i !== index)
														);
													}}
												>
													<DeleteOutline />
												</IconButton>
											</Box>
											<Typography
												variant="body2"
												sx={{
													fontStyle: "italic",
													marginTop: "-15px",
													fontSize: "12px",
													color: "#AEAEAE",
												}}
											>
												{item?.datetime?.toLocaleString()}
											</Typography>
											<List>
												{item?.files?.map((file: any, index: number) => {
													return (
														<ListItem
															key={index}
															disablePadding={true}
															dense={true}
														>
															<ListItemIcon>
																<FilePresent />
															</ListItemIcon>
															<ListItemText primary={file} />
														</ListItem>
													);
												})}
											</List>
										</Box>
										<>
											{index < history.length - 1 ? (
												<Divider sx={{ width: "90%", margin: "auto" }} />
											) : (
												<></>
											)}
										</>
									</Box>
								);
							})}
						</Box>
					) : (
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								justifyContent: "center",
								height: "100%",
								width: "100%",
							}}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									justifyContent: "center",
									height: "100%",
									width: "100%",
								}}
							>
								<Typography variant="h5" sx={{ mb: "20px" }}>
									No history to display
								</Typography>
							</Box>
						</Box>
					)}
				</Paper>
			</Box>
		</Box>
	);
};
