const audioBufferToWav = (buffer: any) => {
	const numOfChannels = buffer.numberOfChannels;
	const length = buffer.length * numOfChannels * 2 + 44;
	const output = new ArrayBuffer(length);
	const view = new DataView(output);

	const writeString = (view: any, offset: any, string: any) => {
		for (let i = 0; i < string.length; i++) {
			view.setUint8(offset + i, string.charCodeAt(i));
		}
	};

	let offset = 0;
	writeString(view, offset, "RIFF"); // ChunkID
	offset += 4;
	view.setUint32(offset, 36 + buffer.length * numOfChannels * 2, true); // ChunkSize
	offset += 4;
	writeString(view, offset, "WAVE"); // Format
	offset += 4;
	writeString(view, offset, "fmt "); // Subchunk1ID
	offset += 4;
	view.setUint32(offset, 16, true); // Subchunk1Size (PCM)
	offset += 4;
	view.setUint16(offset, 1, true); // AudioFormat (PCM)
	offset += 2;
	view.setUint16(offset, numOfChannels, true); // NumChannels
	offset += 2;
	view.setUint32(offset, buffer.sampleRate, true); // SampleRate
	offset += 4;
	view.setUint32(offset, buffer.sampleRate * 2 * numOfChannels, true); // ByteRate
	offset += 4;
	view.setUint16(offset, numOfChannels * 2, true); // BlockAlign
	offset += 2;
	view.setUint16(offset, 16, true); // BitsPerSample
	offset += 2;
	writeString(view, offset, "data"); // Subchunk2ID
	offset += 4;
	view.setUint32(offset, buffer.length * numOfChannels * 2, true); // Subchunk2Size
	offset += 4;

	const channels = [];
	for (let i = 0; i < numOfChannels; i++) {
		channels.push(buffer.getChannelData(i));
	}

	let sampleIndex = 0;
	while (sampleIndex < buffer.length) {
		for (let i = 0; i < numOfChannels; i++) {
			let sample = channels[i][sampleIndex] * 0x7fff;
			view.setInt16(offset, sample, true);
			offset += 2;
		}
		sampleIndex++;
	}

	return new Blob([output], { type: "audio/wav" });
};

export const convertWebmBlobToWavBlob = async (webmBlob: Blob) => {
	const audioContext = new window.AudioContext();
	const arrayBuffer = await webmBlob.arrayBuffer();
	const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
	const wavBlob = audioBufferToWav(audioBuffer);
	return wavBlob;
};
