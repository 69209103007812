import { Avatar, Box, Card, CardContent, CardHeader, IconButton, Link, Typography } from "@mui/material";
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import React, { useEffect } from "react";
import { searchUsers } from "../../../apis/assistant";


interface IBasicUser {
    id: string;
    active: boolean;
    name: any;
    displayName: string;
    title: string;
    photo: string;
    emails: any[];
    phones: any[];
    preferredLanguage: string;
    timezone: string;
}


export const People: React.FC = (props: any) => {
    const [users, setUsers] = React.useState<IBasicUser[]>([]);

    useEffect(() => {
        searchUsers(props.utterance.slots[0].value.value).then((res: any) => {
            if (!res.data) {
                return
            }
            res.data.values.map((user: IBasicUser) => {
                var user_obj: IBasicUser = {
                    id: user.id,
                    active: user.active,
                    name: user.name,
                    displayName: user.displayName,
                    title: user.title,
                    photo: user.photo,
                    emails: user.emails,
                    phones: user.phones,
                    preferredLanguage: user.preferredLanguage,
                    timezone: user.timezone,
                };
                var alreadyThere = false;
                users.map((u: IBasicUser) => {
                    if (u.id === user_obj.id) {
                        alreadyThere = true;
                    }
                });
                if (!alreadyThere) {
                    setUsers(users => [...users, user_obj]);
                }
            });
        });
    }, []);

    const getLanguageFromLocale = (locale: string) => {
        const displayNames = new Intl.DisplayNames(locale, { type: 'language' });
        return displayNames.of(locale);
    }
    /*
        def basic(self):
            return {
                "id": self.id,
                "active": self.active,
                "name": self.name.json if self.name else None,
                "displayName": self.display_name
                if self.display_name is not None
                else f"{self.name.given_name} {self.name.family_name}",
                "title": self.title,
                "photos": [photo.json for photo in self.photos],
                "preferredLanguage": self.preferred_language,
                "timezone": self.timezone,
            }
    */
    return (
        <>
            {users.map((user: IBasicUser) => {
                return (
                    <Card key={user.id} sx={{}}>
                        <CardHeader
                            avatar={
                                <Avatar src={user.photo} alt={user.name.givenName + " " + user.name.familyName} />
                            }
                            action={
                                <IconButton>
                                    <MoreVertIcon />
                                </IconButton>
                            }
                            title={user.displayName ? user.displayName : `${user.name.givenName} ${user.name.familyName}`}
                            subheader={user.title}
                        />
                        <CardContent>
                            {user.emails.length > 0 &&
                                <>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Email{user.emails.length > 1 ? "s" : ""}
                                        <>
                                            {user.emails.map((email: any) => {
                                                return (
                                                    <Typography
                                                        variant="body2"
                                                        color="textSecondary"
                                                        component="p"
                                                        sx={{
                                                            wordBreak: "break-all",
                                                            fontStyle: "italic",
                                                            "@media (max-width: 600px)": {
                                                                fontSize: "12px",
                                                            },
                                                        }}
                                                    >
                                                        {email.type}: <Link href={`mailto:${email.value}`}>{email.value}</Link>
                                                    </Typography>
                                                );
                                            }
                                            )}
                                        </>
                                    </Typography>
                                </>
                            }
                            {user.phones.length > 0 &&
                                <>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Phone{user.phones.length > 1 ? "s" : ""}
                                        <>
                                            {user.phones.map((phone: any) => {
                                                return (
                                                    <Typography
                                                        variant="body2"
                                                        color="textSecondary"
                                                        component="p"
                                                        sx={{
                                                            wordBreak: "break-all",
                                                            fontStyle: "italic",
                                                            "@media (max-width: 600px)": {
                                                                fontSize: "12px",
                                                            },
                                                        }}
                                                    >
                                                        {phone.type}: <Link href={`mailto:${phone.value}`}>{phone.value}</Link>
                                                    </Typography>
                                                );
                                            }
                                            )}
                                        </>
                                    </Typography>
                                </>
                            }
                            <Typography variant="body2" color="textSecondary" component="p" sx={{ marginTop: "10px" }}>
                                Global information
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                                sx={{
                                    wordBreak: "break-all",
                                    fontStyle: "italic",
                                    "@media (max-width: 600px)": {
                                        fontSize: "12px",
                                    },
                                }}
                            >
                                Language: {getLanguageFromLocale(user.preferredLanguage)}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                                sx={{
                                    wordBreak: "break-all",
                                    fontStyle: "italic",
                                    "@media (max-width: 600px)": {
                                        fontSize: "12px",
                                    },
                                }}
                            >
                                Timezone: {user.timezone}, ({
                                    (new Date()).toLocaleString('en-US', {
                                        timeZone: user.timezone, hour: '2-digit', minute: '2-digit'
                                    })})
                            </Typography>
                        </CardContent>
                    </Card >
                )
            })}
            {
                users.length === 0 &&
                <Typography
                    sx={{
                        fontSize: "13.3333px",
                        textAlign: "justify",
                        fontStyle: "italic",
                        fontWeight: "bold",
                        margin: "auto",
                    }}
                >
                    Oops ... No user found.
                </Typography>
            }
        </>
    )
}
