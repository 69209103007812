import { Box, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DataSummaryRfp } from "../components/rfps/dataSummaryRfp";
import { FileUploadHistory } from "../components/uploadWithHistory";

export const RFPSummary: React.FC = () => {
	const usageCaseStorageKey = "last_rfp_use_case_1";
	const usageHistoryStorageKey = "rfp_use_case_1_history";
	const extensionFiles = ".pdf";
	const [selectedFile, setSelectedFile] = useState<{}>(
		localStorage.getItem(usageCaseStorageKey)
			? JSON.parse(localStorage.getItem(usageCaseStorageKey) || "{}")
			: {}
	);
	const [selectedLanguage, setSelectedLanguage] = useState<string>("english");
	const [apiCall, setApiCall] = useState<string>("/v1/rfps/english");

	useEffect(() => {
		if (selectedLanguage) {
			setApiCall(`/v1/rfps/${selectedLanguage}`);
		}
	}, [selectedLanguage]);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				position: "relative",
				overflow: "hidden",
				height: "calc(100vh - 112px)",
				maxWidth: "100vw",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					contain: "content",
					alignItems: "center",
					justifyContent: "center",
					width: "100%",
					height: "100%",
					border: "none",
				}}
			>
				<FileUploadHistory
					apiCall={apiCall}
					setSelectedFile={setSelectedFile}
					setSelectedLanguage={setSelectedLanguage}
					usageCaseStorageKey={usageCaseStorageKey}
					usageHistoryStorageKey={usageHistoryStorageKey}
					extensionFiles={extensionFiles}
				/>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
						height: "calc(100% - 25px)",
						my: "10px",
						width: "70%",
						mr: "25px",
					}}
				>
					<Paper
						sx={{
							display: "flex",
							height: "100%",
							width: "100%",
							overflowX: "auto",
							scrollbarColor: "background: rgb(76, 116, 166)",
							scrollbarGutter: "scrollbar",
							"&::-webkit-scrollbar": {
								width: "7px",
								borderRadius: "10px",
							},
							"&::-webkit-scrollbar-thumb": {
								background: "rgb(76, 116, 166)",
								borderRadius: "10px",
							},
							"&::-webkit-scrollbar-thumb:hover": {
								backgroundColor: "rgba(0, 0, 0, 0.3)",
							},
						}}
					>
						{Object.keys(selectedFile).length ? (
							<DataSummaryRfp language={selectedLanguage} data={selectedFile} />
						) : (
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									justifyContent: "center",
									height: "100%",
									width: "100%",
								}}
							>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										justifyContent: "center",
										height: "100%",
										width: "100%",
									}}
								>
									<Typography variant="h4" sx={{ mb: "20px" }}>
										Data Summary
									</Typography>
									<Typography variant="h5" sx={{ mb: "20px" }}>
										No data to display
									</Typography>
								</Box>
							</Box>
						)}
					</Paper>
				</Box>
			</Box>
		</Box>
	);
};
