import { Send } from "@mui/icons-material";
import { Box, Chip, List, ListItem, Paper, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { summarize } from "../../apis/voices";

interface SummaryProps {
	dataId: number;
	transcription: string;
	language: string;
}

export const Summary: React.FC<SummaryProps> = (props) => {
	const [summariesList, setSummariesList] = useState<{ [key: number]: string }>(
		JSON.parse(localStorage.getItem("voice-summaries") || "{}")
	);
	const [entitiesList, setEntitiesList] = useState<{ [key: number]: string[] }>(
		JSON.parse(localStorage.getItem("voice-entities") || "{}")
	);
	const [insightsList, setInsightsList] = useState<{ [key: number]: string[] }>(
		JSON.parse(localStorage.getItem("voice-insights") || "{}")
	);

	const [summary, setSummary] = useState("");
	const [entities, setEntity] = useState<string[]>([]);
	const [insights, setInsights] = useState<string[]>([]);
	const [language, setLanguage] = useState();

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (
			props.dataId &&
			summariesList[props.dataId] &&
			entitiesList[props.dataId] &&
			insightsList[props.dataId]
		) {
			setIsLoading(false);
			setSummary(summariesList[props.dataId]);
			setEntity(entitiesList[props.dataId]);
			setInsights(insightsList[props.dataId]);
			return;
		}
		setIsLoading(true);
	}, [props.transcription]);

	const callSummaryApi = async () => {
		if (!props.transcription) {
			console.error("No transcription found");
			return;
		}
		summarize(props.transcription, props.language)
			.then((res) => {
				var data = res.data;
				setSummariesList({ ...summariesList, [props.dataId]: data.summary });
				setSummary(data.summary);
				setEntitiesList({ ...entitiesList, [props.dataId]: data.entities });
				setEntity(data.entities);
				setInsightsList({ ...insightsList, [props.dataId]: data.key_insights });
				setInsights(data.key_insights);

				setIsLoading(false);
			})
			.catch((err) => {
				console.error(err);
				setIsLoading(false);
			});
	};

	useEffect(() => {
		localStorage.setItem("voice-summaries", JSON.stringify(summariesList));
	}, [summariesList]);

	useEffect(() => {
		localStorage.setItem("voice-entities", JSON.stringify(entitiesList));
	}, [entitiesList]);

	useEffect(() => {
		localStorage.setItem("voice-insights", JSON.stringify(insightsList));
	}, [insightsList]);

	useEffect(() => {
		console.log("Summary props:", props);
		if (props.dataId === null || props.dataId === undefined) {
			console.error("No audio data ID provided");
			return;
		}
		if (props.transcription && !summariesList[props.dataId]) {
			callSummaryApi();
		} else {
			setIsLoading(false);
		}
	}, [props.transcription]);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				height: "100%",
				width: "100%",
				ml: "25px",
			}}
		>
			<Paper
				sx={{
					display: "flex",
					flexDirection: "column",
					height: "fit-content",
					width: "100%",
					padding: "10px",
				}}
			>
				<Typography variant="h6">Summary</Typography>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						height: "100%",
						width: "100%",
						padding: "10px",
						marginTop: "10px",
						overflow: "auto",
						textAlign: "justify",
					}}
				>
					{isLoading ? (
						<Typography>Loading...</Typography>
					) : (
						<>
							<Typography>{summary}</Typography>
							<Stack
								direction="row"
								useFlexGap
								flexWrap="wrap"
								spacing={1}
								sx={{
									marginTop: "15px",
									width: "100%",
									noWrap: { whiteSpace: "nowrap" },
								}}
							>
								{entities.map((entity, index) => (
									<Chip key={index} label={entity} />
								))}
							</Stack>
						</>
					)}
				</Box>
			</Paper>
			<Paper
				sx={{
					display: "flex",
					flexDirection: "column",
					height: "fit-content",
					width: "100%",
					padding: "10px",
					marginTop: "10px",
				}}
			>
				<Typography variant="h6">Insights</Typography>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						height: "100%",
						width: "100%",
						padding: "10px",
						marginTop: "10px",
						overflow: "auto",
						textAlign: "justify",
					}}
				>
					{isLoading ? (
						<Typography>Loading...</Typography>
					) : (
						<List>
							{insights?.map((insight, index) => (
								<ListItem
									key={index}
									sx={{
										display: "flex",
										flexDirection: "row",
										cursor: "pointer",
										"&:hover": {
											backgroundColor: "rgba(0, 0, 0, 0.1)",
										},
										backgroundColor: "rgba(0, 0, 0, 0)",
									}}
								>
									<Send
										sx={{
											fontSize: "small",
											color: "primary",
											mr: "15px",
											textAlign: "top",
											verticalAlign: "top",
											height: "100%",
										}}
									/>
									{insight}
								</ListItem>
							))}
						</List>
					)}
				</Box>
			</Paper>
		</Box>
	);
};
