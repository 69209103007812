import { MedicalInformation } from "@mui/icons-material";
import {
	Box,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Paper,
	Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { FileUploadHistory } from "../components/uploadWithHistory";

const capitalize = (str: string) => {
	return str.charAt(0).toUpperCase() + str.slice(1);
};

export const DrugExtraction: React.FC = () => {
	const usageCaseStorageKey = "last_rfp_use_case_2";
	const usageHistoryStorageKey = "rfp_use_case_2_history";
	const extensionFiles = ".xlsx,.csv";
	const [selectedFile, setSelectedFile] = useState<{}>(
		localStorage.getItem(usageCaseStorageKey)
			? JSON.parse(localStorage.getItem(usageCaseStorageKey) || "{}")
			: {}
	);
	const [apiCall] = useState<string>("/v1/identify/bpu");

	useEffect(() => {
		console.log(selectedFile);
	}, [selectedFile]);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				position: "relative",
				overflow: "hidden",
				height: "calc(100vh - 112px)",
				maxWidth: "100vw",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					contain: "content",
					alignItems: "center",
					justifyContent: "center",
					width: "100%",
					height: "100%",
					border: "none",
				}}
			>
				<FileUploadHistory
					apiCall={apiCall}
					setSelectedFile={setSelectedFile}
					setSelectedLanguage={null}
					usageCaseStorageKey={usageCaseStorageKey}
					usageHistoryStorageKey={usageHistoryStorageKey}
					extensionFiles={extensionFiles}
				/>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
						height: "calc(100% - 25px)",
						my: "10px",
						width: "70%",
						mr: "25px",
					}}
				>
					<Paper
						sx={{
							display: "flex",
							height: "100%",
							width: "100%",
							overflowX: "auto",
							scrollbarColor: "background: rgb(76, 116, 166)",
							scrollbarGutter: "scrollbar",
							"&::-webkit-scrollbar": {
								width: "7px",
								borderRadius: "10px",
							},
							"&::-webkit-scrollbar-thumb": {
								background: "rgb(76, 116, 166)",
								borderRadius: "10px",
							},
							"&::-webkit-scrollbar-thumb:hover": {
								backgroundColor: "rgba(0, 0, 0, 0.3)",
							},
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								height: "100%",
								p: 2,
							}}
						>
							<Typography variant="h5" sx={{ mb: 2 }}>
								Drug Extracted
							</Typography>
							<Typography variant="body1" sx={{ mb: 2 }}>
								Extracted {(selectedFile as any)?.count} /{" "}
								{(selectedFile as any)?.total} drugs
							</Typography>
							<List>
								{selectedFile &&
									(selectedFile as any)?.drugs?.map((el: any, index: number) => (
										<ListItem
											key={index}
											sx={{
												display: "flex",
												flexDirection: "row",
												width: "100%",
											}}
											alignItems="flex-start"
										>
											<ListItemButton
												sx={{
													p: 1,
													height: "100%",
													verticalAlign: "top !important",
												}}
											>
												<MedicalInformation />
											</ListItemButton>
											<Box
												sx={{
													display: "flex",
													flexDirection: "column",
													alignItems: "flex-start",
													justifyContent: "center",
													width: "100%",
													ml: 2,
												}}
											>
												<List
													sx={{
														p: 1,
														mt: "-4px",
													}}
												>
													<ListItemText
														primary={`Lot (${el?.drug?.num_lot}.${
															el?.drug?.num_sub_lot
														}): ${capitalize(el?.drug?.denomination)}`}
														secondary={
															<Box>
																<Typography variant="body2">
																	Format: <i>{el?.drug?.sl}</i>
																</Typography>
																<Typography variant="body2">
																	Volume:{" "}
																	<b>
																		{el?.drug?.volume
																			? el?.drug?.volume
																			: "No volume provided"}
																	</b>
																</Typography>
															</Box>
														}
													/>
												</List>
											</Box>
										</ListItem>
									))}
							</List>
						</Box>
					</Paper>
				</Box>
			</Box>
		</Box>
	);
};
