import { AxiosResponse } from "axios";
import { cleanLocalStorage, cleanLocalStorageExceptInit } from "../helpers/localStorage";
import { instance } from "./baseApi";

export function forceLogout(): Promise<AxiosResponse> {
	instance
		.post(`/v1/logout`)
		.then((response) => {
			cleanLocalStorageExceptInit();
		})
		.catch((error) => {
			cleanLocalStorage();
		})
		.finally(() => {
			if (window.location.pathname === "/login") {
				return;
			}

			window.location.href = "/login";
		});
	return Promise.resolve({} as AxiosResponse);
}

export function init(): Promise<AxiosResponse> {
	return instance.get(`/init`);
}
