import { Box, CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Markdown from "react-markdown";
import { chatSummary } from "../../../apis/assistant";

export const RequirementSummary: React.FC = () => {
	const [requirements, setRequirements] = useState<any[]>([]);
	const [summary, setSummary] = useState<string>("");
	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		for (let i = 0; i < localStorage.length; i++) {
			const key = localStorage.key(i);
			if (!key) continue;
			const value = localStorage.getItem(key);
			console.log(key, key.includes("prediction"));
			if (key.includes("prediction")) {
				const parsedValue = JSON.parse(value || "");
				setRequirements((prevRequirements) => [...prevRequirements, parsedValue]);
			}
		}
	}, []);

	useEffect(() => {
		if (summary) return;
		if (requirements.length === 0) return;
		chatSummary(requirements)
			.then((res: any) => {
				setSummary(res.data);
				setIsLoading(false);
			})
			.catch((err: any) => {
				console.error(err);
				setIsLoading(false);
			});
	}, [requirements]);

	return (
		<Box
			sx={{
				width: "100%",
				border: "none",
			}}
		>
			{isLoading && (
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "40px",
						width: "40px",
					}}
				>
					<CircularProgress />
				</Box>
			)}
			{!isLoading && (
				<Typography variant="body2" sx={{ mt: 2 }}>
					<Markdown>{summary}</Markdown>
				</Typography>
			)}
		</Box>
	);
};
