import { CssBaseline, ThemeProvider } from "@mui/material";
import { RouterProvider } from "react-router-dom";

import { LoginProvider } from "./components/login/loginContext";

import { useEffect } from "react";
import { init } from "./apis/authentication";
import "./App.css";
import { customTheme } from "./components/palette";
import router from "./routes/index";

function App() {
	useEffect(() => {
		init()
			.then((response) => {
				if (
					window.location.origin.includes("ai-tour") &&
					window.location.pathname === "/"
				) {
					window.location.href = "/graphrag";
				}
			})
			.catch(() => {
				if (window.location.pathname === "/login") {
					return;
				}

				window.location.href = "/login";
			});
	}, []);

	return (
		<LoginProvider>
			<ThemeProvider theme={customTheme}>
				<CssBaseline />
				<RouterProvider router={router} />
			</ThemeProvider>
		</LoginProvider>
	);
}

export default App;
